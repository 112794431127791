application.register("part_form_search", class extends Stimulus.Controller {
    connect() {
        this._searchTimeout = ''
    }

    search(e) {
        const query = e.currentTarget.value

        clearTimeout(this._searchTimeout)
        this._searchTimeout = setTimeout(() => { this.handleFetch(query) }, 300)
    }

    handleFetch(query) {
        const self = this;
        if (query.length > 0) {
            // const url = `?do=searchFulltext&q=${query}`
            const url = 'json-search.json'

            importScript(cdnjs.jquery, () => {
                $.ajax({
                    url: url,
                    data: {ajax: +new Date},
                    dataType: "json",
                }).done(function (payload) {
                    self.targets.find('results').innerHTML = ''
                    const resultHtml = payload[0]
                    self.targets.find('results').insertAdjacentHTML('beforeend', resultHtml)

                    self.targets.find('results').classList.add('state--active')
                });
            })
        } else {
            this.targets.find('results').innerHTML = ''
            this.targets.find('results').classList.remove('state--active')
        }
    }

    hideResults(e) {
        if (e.target.closest('.c-form-search') === null && this.targets.find('results').classList.contains('state--active')) {
            this.targets.find('results').innerHTML = ''
            this.targets.find('results').classList.remove('state--active')
        }
    }
})
