const cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "jquery": "https://cdn.jsdelivr.net/npm/jquery@3.5.0/dist/jquery.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js",
    "popper": "https://cdn.jsdelivr.net/npm/@popperjs/core@2.1.1/dist/umd/popper.min.js",
    "tippy": "https://cdn.jsdelivr.net/npm/tippy.js@6.1.0/dist/tippy.umd.min.js",
    "masonry": "https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "countup": "https://cdn.jsdelivr.net/npm/countup@1.8.2/dist/countUp.min.js",
    "chartist": "https://cdn.jsdelivr.net/npm/chartist@0.11.4/dist/chartist.min.js",
    "chartist_css": "https://cdn.jsdelivr.net/npm/chartist@0.11.4/dist/chartist.min.css"
};