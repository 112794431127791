application.register("part_categories", class extends Stimulus.Controller {
    changeActive(event) {
        let self = this, element=event.currentTarget, selector = element.closest(".part_categories");

        if(element.classList.contains("state--active")) return;

        selector.querySelector(".state--active").classList.remove("state--active");
        selector.scrollLeft = element.closest(".col").offsetLeft - 72;
        element.classList.add("state--active");
    }
});