application.register("part_ui_video", class extends Stimulus.Controller {
    connect() {
        let element = this.element, video = element.querySelector("video");
        video.addEventListener("ended",()=>{
            element.classList.remove("state--playing");
            video.currentTime = 0;
            video.removeAttribute("controls");
        });
    }
    togglePlay() {
        let element = this.element, video = element.querySelector("video");
        if(video.paused) {
            video.play();
            video.setAttribute("controls",true);
            element.classList.add("state--playing");
        }
        else {
            video.removeAttribute("controls");
            video.pause();
            element.classList.remove("state--playing");
        }
    }
});