application.register("comp_map_svg", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;
        let region_svg = element.querySelector("[data-region-svg]");
        let tooltip = element.querySelector(".tooltip");

        window.onmousemove = function (e) {
            tooltip.style.top = e.y + locomotive.scroll.instance.scroll.y + "px";
            tooltip.style.left = (e.clientX + 20) + 'px';
        };

        [...region_svg.querySelectorAll("[data-region]")].map((region) => {

            if (region.getAttribute("data-url")) {
                region.addEventListener("click", () => {
                    let url = region.getAttribute("data-url");

                    swup.loadPage({
                        url: url
                    });
                });
            }

            region.addEventListener("mouseenter", () => {
                region.classList.add("state--hover");
                tooltip.querySelector(".part_ui_heading").textContent = region.getAttribute("data-region");
                tooltip.style.display = "block";
            });

            region.addEventListener("mouseleave", () => {
                region.classList.remove("state--hover");
                tooltip.style.display = "none";
            });
        });
    }
});