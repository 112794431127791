loadStimulus(document);

window.lui_references_responses = {};

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }

    basketAdd(e) {
        document.body.insertAdjacentHTML("beforeend", '<div class="layout_summary_circle"></div>');
        let circles = document.querySelectorAll(".layout_summary_circle")
        let summary_circle =  circles[circles.length - 1];

        let basket = this.targets.find("basketIcon");

        let left = basket.offsetLeft + 24 + "px";

        summary_circle.style.top = e.clientY + "px";
        summary_circle.style.left = e.clientX + "px";

        setTimeout(()=>{
            summary_circle.style.top = basket.offsetTop + 32 + "px";
            summary_circle.style.left = left;
            summary_circle.style.width = "1rem";
            summary_circle.style.height = "1rem";
            summary_circle.style.opacity = "0.1"

            setTimeout(()=>{
                summary_circle.parentElement.removeChild(summary_circle);
            },800);
        },10);

        //LibDialog.action(e.currentTarget, "/assets/dialog-basket.json", loadStimulus(document.querySelector(".lib--dialog")));
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        if(typeof this.element.dataset.libDialogOpen !== "undefined") {
            let url = this.element.getAttribute("data-action-url");

            LibDialog.action(this.element, url, ()=>loadStimulus(document.querySelector(".lib--dialog")) );
        }
    }
    show(e) {
        let element = e.currentTarget;

        if (element === window) {
            element = document.querySelector(`[data-action="DOMContentLoaded@window->lib-dialog#show"]`);
        }

        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide(loadStimulus(document.querySelector(".lib--dialog")));
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        let element = this.element, options = this.data.get("options");
        importScript([cdnjs.popper, cdnjs.tippy],()=> {
            LibTippy(element, options);
        });
    }
});

application.register("lib-slider", class extends Stimulus.Controller {
    connect() {
        LibSlider(this.element, JSON.parse(this.data.get("options")));
    }
});

application.register("lib-masonry", class extends Stimulus.Controller {
    connect() {
        LibMasonry(this.element, JSON.parse(this.data.get("options")));
    }
});

application.register("lib-gallery", class extends Stimulus.Controller {
    connect() {
        LibGallery(this.element, this.data.get("id"));
    }
});

application.register("lib-autosize", class extends Stimulus.Controller {
    connect() {
        importScript(cdnjs.autosize,()=>{
            autosize(this.element);
        });
    }
});

