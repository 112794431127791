application.register("comp_text_aside", class extends Stimulus.Controller {
    toggle(e) {
        let elm = e.currentTarget;
        let item = elm.closest(".elm_item");


        if (!item.classList.contains("state--active")) {
            item.classList.add("state--active");
            locomotive.update();
        } else {
            item.classList.remove("state--active");
            locomotive.update();
        }
    }
});